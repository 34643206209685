<rspl-loader></rspl-loader>
<mat-sidenav-container
  id="sidenavContainer"
  autosize
  class="sidebar-container {{ appType }}"
  [hasBackdrop]="false"
  [ngStyle]="{ 'padding-top.px': headerHeight }"
  [ngClass]="{ onboarding: !onboardingCompleted }"
  #container
>
  <mat-sidenav
    fixedInViewport
    [fixedTopGap]="52"
    #drawer
    class="main-sidenav"
    #sidenav
    mode="over"
    [opened]="
      isSmallWindowSize || !stickyMenu || environment.isZendesk ? false : true
    "
    [ngClass]="{ expanded: menuExpanded || !stickyMenu || isSmallWindowSize }"
    (closedStart)="isSmallWindowSize || !stickyMenu ? undefined : drawer.open()"
    (openedChange)="
      isSmallWindowSize || !stickyMenu ? undefined : openChanged()
    "
  >
    <div class="main-navigation" fxLayout="column">
      <ng-container *ngIf="auth" [ngTemplateOutlet]="Menu"></ng-container>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [ngStyle]="{ 'min-height': 'calc(100vh - ' + headerHeight + 'px)' }"
  >
    <div
      class="backdrop"
      [ngClass]="{ visible: menuExpanded || (drawer.opened && !stickyMenu) }"
      (click)="backdropClick()"
    ></div>

    <mat-toolbar class="header {{ appType }}">
      <mat-toolbar-row
        [style]="
          isSmallWindowSize || environment.isZendesk ? '' : 'padding-left: 2px;'
        "
      >
        <div *ngIf="isStaging || isDemo || isDev" class="header-staging">
          {{ buildType }}
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFlex="100"
          class="header-toolbar"
        >
          <div class="main-menu" fxLayout="row" fxLayoutAlign="start center">
            <button
              (click)="
                isSmallWindowSize || !stickyMenu
                  ? drawer.toggle()
                  : (menuExpanded = !menuExpanded)
              "
              *ngIf="onboardingCompleted && !isZendesk"
              [disabled]="editMode"
              class="icon-menu"
              [ngClass]="{
                opened:
                  isSmallWindowSize || !stickyMenu
                    ? drawer.opened
                    : menuExpanded
              }"
            >
              <div class="bar bar1"></div>
              <div class="bar bar2"></div>
              <div class="bar bar3"></div>
            </button>
            <a
              [routerLink]="editMode || isZendesk ? null : rootPath"
              class="app-logo"
            >
              <mat-icon svgIcon="header-logo"></mat-icon>
            </a>
          </div>
          <div fxLayout="row" fxLayoutGap="20px"></div>
          <div class="account-menu">
            <a
              *ngIf="(isCharity || isTpl) && zendesk"
              mat-icon-button
              matTooltip="Help Center"
              [matTooltipShowDelay]="500"
              target="_blank"
              [href]="zendesk"
              class="help-center-icon"
            >
              <mat-icon>help_outline</mat-icon>
            </a>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [disabled]="editMode"
              aria-label="Account"
            >
              <mat-icon svgIcon="user-icon"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="user-menu">
              <h4>Account</h4>
              <div
                fxLayout="row"
                fxLayoutAlign="start start"
                class="user-header"
                fxLayoutGap="10px"
              >
                <span
                  *ngIf="auth.name"
                  class="user-img"
                  fxLayoutAlign="center center"
                >
                  {{ auth.name[0] }}
                </span>
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  class="name-wrapper"
                >
                  <p class="name">{{ auth.name }}</p>
                  <p class="email">{{ auth.email }}</p>
                </div>
              </div>
              <a
                mat-menu-item
                [routerLink]="['/', 'users', 'profile']"
                *ngIf="onboardingCompleted && !isZendesk"
              >
                <mat-icon>manage_accounts</mat-icon>
                <span>Account settings</span>
              </a>
              <hr *ngIf="(isTpl || isCharity) && onboardingCompleted" />
              <h4 *ngIf="(isTpl || isCharity) && onboardingCompleted">
                {{ isTpl ? 'Partner' : 'Charity' }}
              </h4>
              <div
                fxLayout="row"
                fxLayoutAlign="start start"
                class="organization-header"
                fxLayoutGap="10px"
                *ngIf="(isTpl || isCharity) && onboardingCompleted"
              >
                <span
                  *ngIf="isTpl || (isCharity && !charity?.logo)"
                  class="organization-img"
                  fxLayoutAlign="center center"
                >
                  {{ (partner || charity)?.name[0] }}
                </span>
                <div
                  class="logo-wrapper"
                  fxLayoutAlign="center center"
                  *ngIf="charity?.logo"
                >
                  <img class="logo" [src]="charity.logo" />
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  class="name"
                >
                  <p>{{ (partner || charity)?.name }}</p>
                </div>
              </div>
              <a
                mat-menu-item
                [routerLink]="
                  isStore ? ['/', 'stores', 'settings'] : ['/', 'settings']
                "
                *ngIf="(isTpl || isCharity) && onboardingCompleted"
              >
                <mat-icon>settings</mat-icon>
                <span>{{ isTpl ? 'Partner' : 'Charity' }} Settings</span>
              </a>
              <hr />
              <div fxLayout="column">
                <h4>Zoom</h4>
                <div fxLayout="row" class="user-zoom">
                  <button mat-button (click)="updateZoomLevelDown($event)">
                    <mat-icon>zoom_out</mat-icon>
                  </button>
                  <span class="zoom-level" fxLayoutAlign="center center"
                    >{{ zoomLevel }}%</span
                  >
                  <button mat-button (click)="updateZoomLevelUp($event)">
                    <mat-icon>zoom_in</mat-icon>
                  </button>
                </div>
              </div>
              <hr />
              <div fxLayout="column">
                <h4>Theme</h4>
                <div fxLayout="row" class="user-theme" fxFlex="100">
                  <button
                    class="light"
                    mat-button
                    [fxFlex]="100 / 3"
                    [ngClass]="{ active: !autoTheme && theme === themes.LIGHT }"
                    (click)="setTheme($event, themes.LIGHT)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon
                        svgIcon="theme-dark"
                        class="theme-background"
                      ></mat-icon>
                      <mat-icon svgIcon="theme-light"></mat-icon>
                      <span class="theme-title">Light</span>
                    </div>
                  </button>
                  <button
                    class="auto"
                    mat-button
                    [fxFlex]="100 / 3"
                    [ngClass]="{ active: autoTheme }"
                    (click)="setTheme($event)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon
                        svgIcon="theme-dark"
                        class="theme-background"
                      ></mat-icon>
                      <mat-icon svgIcon="theme-auto"></mat-icon>
                      <span class="theme-title">Auto</span>
                    </div>
                  </button>
                  <button
                    class="dark"
                    mat-button
                    [fxFlex]="100 / 3"
                    [ngClass]="{ active: !autoTheme && theme === themes.DARK }"
                    (click)="setTheme($event, themes.DARK)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon
                        svgIcon="theme-dark"
                        class="theme-background"
                      ></mat-icon>
                      <mat-icon svgIcon="theme-dark"></mat-icon>
                      <span class="theme-title">Dark</span>
                    </div>
                  </button>
                </div>
              </div>
              <hr *ngIf="!isSmallWindowSize" />
              <div fxLayout="column" *ngIf="!isSmallWindowSize">
                <h4>Sidebar Menu</h4>
                <div fxLayout="row" class="menu-type" fxFlex="100">
                  <button
                    class="light"
                    mat-button
                    fxFlex="50"
                    [ngClass]="{ active: stickyMenu }"
                    (click)="setStickyMenu($event, true)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon>first_page</mat-icon>
                      <span class="theme-title">Dock</span>
                    </div>
                  </button>
                  <button
                    class="auto"
                    mat-button
                    fxFlex="50"
                    [ngClass]="{ active: !stickyMenu }"
                    (click)="setStickyMenu($event, false)"
                  >
                    <div fxLayout="column" fxLayoutAlign="center center">
                      <mat-icon>keyboard_double_arrow_left</mat-icon>
                      <span class="theme-title">Hide</span>
                    </div>
                  </button>
                </div>
              </div>
              <hr />
              <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row
        *ngIf="pageHeaderPosition && onboardingCompleted"
        [ngStyle]="{
          width:
            'calc(100vw - ' + pageHeaderPosition.toolbar.reduceWidthBy + 'px)',
          'margin-left.px': pageHeaderPosition.toolbar.left,
          'margin-top.px': -52 * pageHeaderPosition.scrollPercent,
          'height.px': pageHeaderPosition.toolbar.height,
          'padding.px':
            (isSmallWindowSize ? 15 : 30) *
            (1 - pageHeaderPosition.scrollPercent)
        }"
      >
        <div
          #pageHeaderDiv
          class="page-header"
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxFlex="100"
          [ngStyle]="{
            'min-height.px': isSmallWindowSize
              ? undefined
              : pageHeaderPosition.toolbar.height +
                (1 - pageHeaderPosition.scrollPercent) *
                  (75 - pageHeaderPosition.toolbar.height)
          }"
        >
          <div
            class="page-title-wrapper"
            fxLayout="row"
            fxLayoutAlign="start start"
            [ngStyle]="{
              'margin-bottom':
                pageHeaderPosition.scrollPercent === 1 ? 0 : undefined
            }"
          >
            <button
              matTooltip="Back"
              [matTooltipShowDelay]="500"
              [ngStyle]="{
                'margin-top.px': 5 * pageHeaderPosition.scrollPercent,
                'border-color': pageHeaderPosition.back['border-color'],
                scrolled: pageHeaderPosition.buttons.scrolled
              }"
              class="back-button"
              mat-icon-button
              (click)="
                backService.navigateTo(
                  router,
                  pageHeader?.back,
                  pageHeader?.queryParams
                );
                backdropClick()
              "
              *ngIf="pageHeader?.hasBackButton"
              [disabled]="editMode"
            >
              <mat-icon
                [ngStyle]="{
                  color: pageHeaderPosition.back.color
                }"
                >arrow_back
              </mat-icon>
            </button>
            <div
              class="title"
              [ngStyle]="{ 'max-width': pageHeaderPosition.title.width }"
            >
              <h1
                [ngStyle]="{
                  'margin-top.px': 11 * pageHeaderPosition.scrollPercent,
                  filter: pageHeaderPosition.title.filter,
                  'font-size': pageHeaderPosition.title['font-size']
                }"
                [innerHTML]="pageHeader?.pageTitle"
              ></h1>
              <p
                [ngStyle]="{
                  opacity: 1 - pageHeaderPosition.scrollPercent,
                  'min-height.px':
                    20 *
                    (pageHeaderPosition.scrollPercent > 0.8
                      ? 1 - pageHeaderPosition.scrollPercent
                      : 1),
                  'height.px':
                    20 *
                    (pageHeaderPosition.scrollPercent > 0.8
                      ? 1 - pageHeaderPosition.scrollPercent
                      : 1)
                }"
              >
                {{ pageHeader?.subtitle }}
              </p>
            </div>
          </div>
          <div
            class="actions"
            *ngIf="!smallWindowSizes.includes(windowSize)"
            fxLayout="row wrap"
            fxLayoutAlign="end"
            [ngStyle]="{
              'margin-top.px': pageHeaderPosition.scrollPercent * 10
            }"
          >
            <ng-container
              *ngFor="let action of pageHeader?.actions"
              [ngTemplateOutlet]="
                !!action.link
                  ? Link
                  : !!action.externalLink
                  ? ExternalLink
                  : Button
              "
              [ngTemplateOutletContext]="{ action: action }"
            ></ng-container>
          </div>
          <div
            class="actions"
            *ngIf="
              smallWindowSizes.includes(windowSize) && visibleActionsCnt > 0
            "
          >
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="more-options"
              [ngStyle]="{
                'margin-top.px': 5 * pageHeaderPosition.scrollPercent,
                'border-color': pageHeaderPosition.back['border-color'],
                'background-color':
                  pageHeaderPosition.buttons['background-color'],
                scrolled: pageHeaderPosition.buttons.scrolled
              }"
              fxLayoutAlign="center center"
              [disabled]="
                isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded
              "
            >
              <mat-icon
                [ngStyle]="{
                  color: pageHeaderPosition.back.color
                }"
                >more_vert</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu">
              <ng-container
                *ngFor="let action of pageHeader?.actions"
                [ngTemplateOutlet]="
                  !!action.link
                    ? DropdownLink
                    : !!action.externalLink
                    ? DropdownExternalLink
                    : DropdownButton
                "
                [ngTemplateOutletContext]="{ action: action }"
              ></ng-container>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <div
      class="content"
      [ngClass]="{ 'captain-app': isCaptain, zendesk: isZendesk }"
      [style]="
        isSmallWindowSize || environment.isZendesk || !stickyMenu
          ? ''
          : 'padding-left: 95px'
      "
    >
      <div class="staging-bg" *ngIf="isStaging"></div>
      <div class="dev-bg" *ngIf="isDev && !isTest"></div>
      <div class="test-bg" *ngIf="isTest"></div>
      <div [style.zoom]="zoom">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #ButtonContent let-action="action">
  <mat-icon
    *ngIf="action.icon && pageHeaderPosition.buttons.showIcon"
    [ngStyle]="{ opacity: pageHeaderPosition.buttons.iconOpacity }"
    [matTooltip]="action.actionTitle"
    [matTooltipShowDelay]="500"
    [svgIcon]="action.icon.name"
    >{{ action.icon }}</mat-icon
  >
  <span
    style="overflow: hidden; display: flex"
    [ngStyle]="{
      opacity: pageHeaderPosition.buttons.txtOpacity,
      'font-size': (1 - pageHeaderPosition.scrollPercent) * 14 + 'px'
    }"
    >{{ action.actionTitle }}</span
  >
</ng-template>
<ng-template #Button let-action="action">
  <button
    mat-raised-button
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{
      hidden: !!action.hidden && !!action.hidden(),
      scrolled: pageHeaderPosition.buttons.showIcon
    }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [ngStyle]="{
      'border-color': pageHeaderPosition.buttons['border-color'],
      padding: pageHeaderPosition.buttons.padding,
      'background-color': pageHeaderPosition.buttons['background-color']
    }"
    (click)="action.actionMethod()"
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
  >
    <ng-container
      [ngTemplateOutlet]="ButtonContent"
      [ngTemplateOutletContext]="{ action: action }"
    ></ng-container>
  </button>
</ng-template>
<ng-template #Link let-action="action">
  <a
    mat-raised-button
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{
      hidden: !!action.hidden && !!action.hidden(),
      scrolled: pageHeaderPosition.buttons.showIcon
    }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [ngStyle]="{
      'border-color': pageHeaderPosition.buttons['border-color'],
      padding: pageHeaderPosition.buttons.padding,
      'background-color': pageHeaderPosition.buttons['background-color']
    }"
    [routerLink]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded)
        ? null
        : action.link
    "
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
  >
    <ng-container
      [ngTemplateOutlet]="ButtonContent"
      [ngTemplateOutletContext]="{ action: action }"
    ></ng-container>
  </a>
</ng-template>
<ng-template #ExternalLink let-action="action">
  <a
    mat-raised-button
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{
      hidden: !!action.hidden && !!action.hidden(),
      scrolled: pageHeaderPosition.buttons.showIcon
    }"
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [ngStyle]="{
      'border-color': pageHeaderPosition.buttons['border-color'],
      padding: pageHeaderPosition.buttons.padding,
      'background-color': pageHeaderPosition.buttons['background-color']
    }"
    [href]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded)
        ? null
        : action.externalLink
    "
    [target]="action.target || '_blank'"
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
  >
    <ng-container
      [ngTemplateOutlet]="ButtonContent"
      [ngTemplateOutletContext]="{ action: action }"
    ></ng-container>
  </a>
</ng-template>
<ng-template #DropdownButton let-action="action">
  <button
    mat-menu-item
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    (click)="action.actionMethod()"
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
    class="{{ action.cssClass ? action.cssClass() : '' }}"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #DropdownLink let-action="action">
  <a
    mat-menu-item
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    [routerLink]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded)
        ? null
        : action.link
    "
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
    class="{{ action.cssClass ? action.cssClass() : '' }}"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>
<ng-template #DropdownExternalLink let-action="action">
  <a
    mat-menu-item
    *ngIf="!action.hidden || !action.hidden()"
    [ngClass]="{ hidden: !!action.hidden && !!action.hidden() }"
    [href]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded)
        ? null
        : action.externalLink
    "
    [disabled]="
      (isSmallWindowSize || !stickyMenu ? sidenav.opened : menuExpanded) ||
      (!!action.disabled && !!action.disabled())
    "
    class="{{ action.cssClass ? action.cssClass() : '' }}"
    [target]="action.target || '_blank'"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>

<ng-template #MenuItemContent let-menuItem="menuItem">
  <mat-icon [svgIcon]="menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
  <span>{{ menuItem.title() }}</span>
  <div
    class="badge-wrapper"
    fxFlex
    fxLayoutAlign="end center"
    *ngIf="menuItem.counter && (this[menuItem.counter] || 0) > 0"
  >
    <span class="badge" fxLayoutAlign="center center">{{
      this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]
    }}</span>
  </div>
  <mat-icon
    class="badge-warn"
    *ngIf="
      menuItem.subtitle &&
      menuItem.subtitle.show({ partner: partner }) &&
      menuItem.subtitle.isWarning
    "
    color="warn"
    [matTooltipShowDelay]="1000"
    [matTooltip]="menuItem.subtitle.value"
  >
    warning
  </mat-icon>
</ng-template>
<ng-template #SingleMenuItem let-menuItem="menuItem" let-isSubmenu="isSubmenu">
  <a
    class="nav-item"
    fxLayout="row"
    [ngClass]="{ disabled: menuItem.smallScreenDisabled || editMode, 'sub-menu': isSubmenu }"
    fxLayoutAlign="start center"
    [routerLink]="menuItem.route"
    routerLinkActive="active"
    [matTooltip]="
      isSubmenu || menuExpanded || isSmallWindowSize
        ? undefined
        : menuItem.title() +
          (menuItem.subtitle && menuItem.subtitle.show({ partner: partner })
            ? menuItem.subtitle.value
            : '') +
          (menuItem.counter && (this[menuItem.counter] || 0) > 0
            ? ' (' +
              (this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]) +
              ')'
            : '')
    "
    [matTooltipShowDelay]="300"
    matTooltipPosition="right"
    (click)="backdropClick()"
    *ngIf="
      menuItem.route &&
      (!menuItem.visible ||
        menuItem.visible({
          partner: partner,
          charity: charity,
          onboardingCompleted: onboardingCompleted,
          permissions: auth?.permissions,
          roles: auth.authorization.roles
        }))
    "
  >
    <ng-container
      *ngIf="!menuItem.children"
      [ngTemplateOutlet]="MenuItemContent"
      [ngTemplateOutletContext]="{ menuItem: menuItem }"
    >
    </ng-container>
  </a>
  <a
    class="nav-item"
    fxLayout="row"
    [ngClass]="{ disabled: menuItem.smallScreenDisabled || editMode, 'sub-menu': isSubmenu }"
    fxLayoutAlign="start center"
    target="_blank"
    [href]="menuItem.externalLink"
    [matTooltip]="
      isSubmenu || menuExpanded || isSmallWindowSize
        ? undefined
        : menuItem.title() +
          (menuItem.subtitle && menuItem.subtitle.show({ partner: partner })
            ? menuItem.subtitle.value
            : '') +
          (menuItem.counter && (this[menuItem.counter] || 0) > 0
            ? ' (' +
              (this[menuItem.counter] > 99 ? '99+' : this[menuItem.counter]) +
              ')'
            : '')
    "
    [matTooltipShowDelay]="300"
    matTooltipPosition="right"
    (click)="backdropClick()"
    *ngIf="
      menuItem.externalLink &&
      (!menuItem.visible ||
        menuItem.visible({
          partner: partner,
          charity: charity,
          onboardingCompleted: onboardingCompleted,
          permissions: auth?.permissions,
          roles: auth.authorization.roles
        }))
    "
  >
    <ng-container
      *ngIf="!menuItem.children"
      [ngTemplateOutlet]="MenuItemContent"
      [ngTemplateOutletContext]="{ menuItem: menuItem }"
    >
    </ng-container>
  </a>
</ng-template>
<ng-template #Menu>
  <mat-accordion>
    <ng-container *ngFor="let menuItem of environment.menu">
      <ng-container
        *ngIf="!menuItem.children"
        [ngTemplateOutlet]="SingleMenuItem"
        [ngTemplateOutletContext]="{ menuItem: menuItem, isSubmenu: false }"
      >
      </ng-container>
      <ng-container *ngIf="menuItem.children">
        <a
          class="nav-item"
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="
            !isSmallWindowSize &&
            (!menuItem.visible ||
              menuItem.visible({
                partner: partner,
                charity: charity,
                onboardingCompleted: onboardingCompleted,
                permissions: auth?.permissions,
                roles: auth.authorization.roles
              })) &&
            !menuExpanded &&
            stickyMenu
          "
          (click)="subMenuTrigger.openMenu()"
          [ngClass]="{
            disabled: editMode,
            active: isMenuExpanded(menuItem.expanded, currentPath),
            expanded: subMenuTrigger.menuOpen
          }"
          [matTooltip]="
            menuExpanded || isSmallWindowSize
              ? undefined
              : menuItem.title() +
                (menuItem.subtitle &&
                menuItem.subtitle.show({ partner: partner })
                  ? menuItem.subtitle.value
                  : '') +
                (menuItem.counter && (this[menuItem.counter] || 0) > 0
                  ? ' (' +
                    (this[menuItem.counter] > 99
                      ? '99+'
                      : this[menuItem.counter]) +
                    ')'
                  : '')
          "
          [matTooltipShowDelay]="300"
          matTooltipPosition="right"
        >
          <mat-icon [svgIcon]="menuItem.svgIcon">{{ menuItem.icon }}</mat-icon>
          <span>{{ menuItem.title() }}</span>
          <div
            #subMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="subMenu"
          ></div>
        </a>
        <mat-menu
          #subMenu="matMenu"
          class="side-menu-submenu"
          xPosition="after"
          [overlapTrigger]="true"
        >
          <h3>{{ menuItem.title() }}</h3>
          <ng-container
            *ngFor="let subMenuItem of menuItem.children"
            [ngTemplateOutlet]="SingleMenuItem"
            [ngTemplateOutletContext]="{
              menuItem: subMenuItem,
              isSubmenu: true
            }"
          >
          </ng-container>
        </mat-menu>
        <mat-expansion-panel
          #configExpansionPanel
          [class.mat-elevation-z0]="true"
          *ngIf="
            
            ((!menuItem.visible ||
              menuItem.visible({
                partner: partner,
                charity: charity,
                onboardingCompleted: onboardingCompleted,
                permissions: auth?.permissions,
                roles: auth.authorization.roles
              })) &&
              (isSmallWindowSize || (menuExpanded || !stickyMenu)))
          "
          [expanded]="isMenuExpanded(menuItem.expanded, currentPath)"
        >
          <mat-expansion-panel-header class="nav-item">
            <a fxLayout="row" fxLayoutAlign="start center">
              <mat-icon [svgIcon]="menuItem.svgIcon">{{
                menuItem.icon
              }}</mat-icon>
              <span>{{ menuItem.title() }}</span>
            </a>
          </mat-expansion-panel-header>

          <ng-container
            *ngFor="let subMenuItem of menuItem.children"
            [ngTemplateOutlet]="SingleMenuItem"
            [ngTemplateOutletContext]="{
              menuItem: subMenuItem,
              isSubmenu: true
            }"
          >
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
  </mat-accordion>
</ng-template>
